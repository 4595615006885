import { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import { logout } from './states/userSlice';
import { useAppSelector, useAppDispatch } from './states/hooks';

import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import EnterOTP from './pages/EnterOTP';
import ChangePassword from './pages/ChangePassword';
import PasswordChangeSuccess from './pages/PasswordChangeSuccess';
import { DEPLOYMENT_ENV, OVERRIDE_INACTIVITY_SECONDS } from 'config';
import {
	ClientEnum,
	DEFAULT_INACTIVITY_MILISECONDS,
	INTEGRATED_WITH_UP,
} from 'constants/constants';
import Legal from 'pages/Legal';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'styles/theme';
import { useIdleTimer } from 'react-idle-timer';
import { LocalStorageService } from 'services/localStorage.service';
import { LocalStorageKeyEnum } from 'types/localStorage';
import { enqueueSnackbar } from 'notistack';
import Verify from 'pages/Verify';
import UnifiedHome from 'pages/Unified/UnifiedHome';

import './styles/index.scss';
import 'overlayscrollbars/overlayscrollbars.css';
import MyAccount from 'pages/MyAccount';

const inactivityMiliSeconds = OVERRIDE_INACTIVITY_SECONDS
	? Number(OVERRIDE_INACTIVITY_SECONDS) * 1000
	: null;

//https://ch5x4xlvua.execute-api.us-east-1.amazonaws.com
function App() {
	const {
		user: { userAuth },
		changePassword: { otp },
	} = useAppSelector((state) => state);
	const dispatch = useAppDispatch();
	// const { pathname } = useLocation();
	// const [searchParams, _] = useSearchParams();
	// const token = searchParams.get('accessToken');

	useEffect(() => {
		const applyingCustomHTMLHead = () => {
			const PUBLIC_URL = document.URL;
			const ICON =
				DEPLOYMENT_ENV === ClientEnum.ARISTA
					? `${PUBLIC_URL}/arista_favicon.png`
					: DEPLOYMENT_ENV === ClientEnum.MAZDA
					? `${PUBLIC_URL}/mazda_favicon.png`
					: DEPLOYMENT_ENV === ClientEnum.WHEELTEK
					? `${PUBLIC_URL}/wheeltek_favicon.ico`
					: DEPLOYMENT_ENV === ClientEnum.MOTORACE
					? `${PUBLIC_URL}/motorace_favicon.ico`
					: DEPLOYMENT_ENV === ClientEnum.NEWNEMAR
					? `${PUBLIC_URL}/newnemar_favicon.ico`
					: DEPLOYMENT_ENV === ClientEnum.CELESTINA
					? `${PUBLIC_URL}/celestina_favicon.ico`
					: `${PUBLIC_URL}/tvsd_favicon.png`;

			const tabIcon: any = document.getElementById('tab-icon');
			if (tabIcon) {
				tabIcon.href = ICON;
			}

			const tabIconApple: any = document.getElementById('tab-icon-apple');
			if (tabIconApple) {
				tabIconApple.href = ICON;
			}

			document.title =
				DEPLOYMENT_ENV === ClientEnum.ARISTA
					? `Arista Management Dashboard`
					: DEPLOYMENT_ENV === ClientEnum.MAZDA
					? `Mazda Management Dashboard`
					: DEPLOYMENT_ENV === ClientEnum.WHEELTEK
					? `Wheeltek Portal`
					: DEPLOYMENT_ENV === ClientEnum.MOTORACE
					? `MotorAce Portal`
					: DEPLOYMENT_ENV === ClientEnum.NEWNEMAR
					? `New Nemar Portal`
					: DEPLOYMENT_ENV === ClientEnum.CELESTINA
					? `Celestina Portal`
					: 'TVSD Management Dashboard';
		};

		applyingCustomHTMLHead();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onIdle = () => {
		// motorace won't have userAuth
		// TODO to combine context and localstorage for any client
		if (!userAuth) return;
		// This function takes care when page is opened on screen but mouse idle for DEFAULT_INACTIVITY_MILISECONDS (2 hours) and automatically log out at that second. If the app is closed will run based on onAction below.

		// 9PM - 6AM we check the expiry and possible log them out
		// 6AM - 9PM we don't check any expiration
		const nowHour = new Date().getHours();
		const turnOnInactivityFeature = nowHour >= 21 || nowHour < 6;

		if (
			turnOnInactivityFeature &&
			(inactivityMiliSeconds === null ||
				(inactivityMiliSeconds && inactivityMiliSeconds > 0))
		) {
			console.log('For security reason, you are logged out due to inactivity.');
			dispatch(logout());
			enqueueSnackbar(
				'For security reason, you are logged out due to inactivity.',
				{
					variant: 'info',
				},
			);
		}
	};

	const onAction = () => {
		// motorace won't have userAuth
		// TODO to combine context and localstorage for any client
		if (!userAuth) return;
		// This functions updates the storage expiration and will log the user out when there is new user action & there is no storage item. This is used when the user have leave the browser closed for more than DEFAULT_INACTIVITY_MILISECONDS (2 hours) which onIdle above can't handle.

		// 9PM - 6AM we check the expiry and possibly log them out
		// 6AM - 9PM we don't check any expiration
		const nowHour = new Date().getHours();
		const turnOnInactivityFeature = nowHour >= 21 || nowHour < 6;

		if (inactivityMiliSeconds && inactivityMiliSeconds < 0) {
			// no need to do anything
		} else {
			const storageObject = turnOnInactivityFeature
				? LocalStorageService.getWithExpiry(LocalStorageKeyEnum.USER)
				: LocalStorageService.getWithExpiry(LocalStorageKeyEnum.USER, false);
			if (storageObject) {
				// expiring the localstorage if the storageDataLastAssigned is less than today
				if (
					storageObject.storageDataLastAssigned &&
					new Date(storageObject.storageDataLastAssigned)
						.toISOString()
						.split('T')[0] < new Date().toISOString().split('T')[0]
				) {
					const secondStorageObject = LocalStorageService.getWithExpiry(
						LocalStorageKeyEnum.USER,
					);
					if (!secondStorageObject) return;
				}
				LocalStorageService.setWithExpiry(
					LocalStorageKeyEnum.USER,
					storageObject,
					inactivityMiliSeconds && inactivityMiliSeconds > 0
						? inactivityMiliSeconds
						: DEFAULT_INACTIVITY_MILISECONDS,
				);
			} else {
				dispatch(logout());
			}
		}
	};

	useIdleTimer({
		onIdle,
		onAction,
		timeout:
			inactivityMiliSeconds && inactivityMiliSeconds > 0
				? inactivityMiliSeconds
				: DEFAULT_INACTIVITY_MILISECONDS,
		throttle: 500,
	});

	return (
		<ThemeProvider theme={theme}>
			<div className="App h-full relative">
				<BrowserRouter>
					<div className="h-full">
						<Routes>
							<Route path="/login" element={<Login />} />
							<Route
								path="/*"
								element={INTEGRATED_WITH_UP ? <UnifiedHome /> : <Home />}
							/>
							<Route path="/legal" element={<Legal />} />
							<Route path="/my-account" element={<MyAccount />} />
							<Route path="/forgot-password" element={<ForgotPassword />} />
							<Route path="/enter-otp" element={<EnterOTP />} />
							<Route
								path="/change-password"
								element={otp.length > 0 ? <ChangePassword /> : <Login></Login>}
							/>
							<Route
								path="/password-change-success"
								element={<PasswordChangeSuccess />}
							/>
							<Route path="/verify" element={<Verify />} />
						</Routes>
					</div>
				</BrowserRouter>
			</div>
		</ThemeProvider>
	);
}

export default App;
