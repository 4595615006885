import React, { useContext, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../states/hooks';
import { login, logout } from '../states/userSlice';
import isEmail from 'validator/lib/isEmail';
import BotBar from '../components/BotBar';
import LazyloadImg from 'components/LazyLoadingImg';
import { BACKEND_URL, OVERRIDE_INACTIVITY_SECONDS, UP_ENDPOINT } from 'config';

import {
	TextField,
	Button,
	Typography,
	IconButton,
	OutlinedInput,
	InputLabel,
	InputAdornment,
	FormHelperText,
	FormControl,
	Collapse,
	Alert,
	useTheme,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import {
	CLIENT_BACKGROUND,
	CLIENT_LOGO,
	DEFAULT_INACTIVITY_MILISECONDS,
	INTEGRATED_WITH_UP,
} from 'constants/constants';
import { LocalStorageService } from 'services/localStorage.service';
import { LocalStorageKeyEnum } from 'types/localStorage';
import { UnifiedComponentPlugin } from 'packages/unified-dashboard/unified-dashboard.adapter';
import { populateLegacyLoginContext } from 'services/auth.service';
import { UnifiedDashboardCtx } from 'packages/unified-dashboard/unified-dashboard.provider';
import { LegacyComponentPlugin } from 'packages/legacy/legacy-dashboard.adapter';
import ZendeskChatWidget from 'components/Zendesk/ChatWidget';

const inactivityMiliSeconds = OVERRIDE_INACTIVITY_SECONDS
	? Number(OVERRIDE_INACTIVITY_SECONDS) * 1000
	: null;

const Login = () => {
	const dispatch = useAppDispatch();
	const ctx = useContext(UnifiedDashboardCtx);

	const { palette } = useTheme();

	const [showPassword, setShowPassword] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	// const [rememberMe, setRememberMe] = useState(false);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (INTEGRATED_WITH_UP) {
			UnifiedComponentPlugin.queryLocalSession().then((res) => {
				if (!res.success || !res.data) {
					window.location.href = `${UP_ENDPOINT}/logout`;
					return;
				}

				ctx.dispatch({
					type: 'UNIFIED_DASHBOARD_LOAD_SESSION',
					payload: res.data,
				});

				dispatch(login(populateLegacyLoginContext(res.data)));
				navigate('/');
				return;
			});
			return;
		}

		LegacyComponentPlugin.queryLocalSession().then((res) => {
			if (res.success && res.data) {
				dispatch(login(res.data));
				navigate('/');
			}
		});
		setLoaded(true);
	}, []);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		event.preventDefault();
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setLoading(true);
		// logout first to make sure all storage clean up in anticipation for unknown ways user can login again while having existing storage
		dispatch(logout());

		const response = await fetch(`${BACKEND_URL}/api/v1/dashboard/login`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ email, password }),
		});

		try {
			const json = await response?.json();
			if (response?.ok && json?.data) {
				setLoading(false);
				const { email, name, token, hasNotViewDashboardManualNotification } =
					json.data;
				const storageObject = {
					email,
					name,
					userAuth: token,
					hasNotViewDashboardManualNotification,
				};

				if (inactivityMiliSeconds && inactivityMiliSeconds < 0) {
					LocalStorageService.set(LocalStorageKeyEnum.USER, storageObject);
				} else {
					// 2 hours
					LocalStorageService.setWithExpiry(
						LocalStorageKeyEnum.USER,
						storageObject,
						inactivityMiliSeconds && inactivityMiliSeconds > 0
							? inactivityMiliSeconds
							: DEFAULT_INACTIVITY_MILISECONDS,
					);
				}

				const decodedToken = jwtDecode(token) as { [key: string]: any };
				const userId = decodedToken.sub;
				const roles = decodedToken.context.roles;
				dispatch(
					login({
						...storageObject,
						userId,
						roles,
					}),
				);
				navigate('/legal');
			} else {
				setLoading(false);
				if (
					json.error === '[E_NoRoleAccess]' ||
					json.error === '[E_Unauthorized]'
				) {
					setError('Incorrect email or password. Please check again.');
				} else {
					setError(json.message || 'no error message from server');
				}
			}
		} catch (e) {
			console.log(e);
			setLoading(false);
			setError('internal server error');
		}
	};

	if (!loaded) {
		return null;
	}

	return (
		<div
			className="login-container bg-local flex flex-col justify-center items-center h-full bg-cover bg-center"
			style={{ backgroundImage: `url(${CLIENT_BACKGROUND})` }}
		>
			<div className="flex flex-col justify-center h-full w-1/4 min-w-[400px]">
				<Collapse in={error.length > 0}>
					<Alert
						severity="error"
						action={
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => {
									setError('');
								}}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
						sx={{ mb: 2, borderRadius: 3, fontSize: 12 }}
					>
						{error}
					</Alert>
				</Collapse>
				<div
					className="bg-white flex flex-col justify-between items-center w-full py-4 gap-3 h-2/3 rounded-2xl relative"
					style={{ boxShadow: '0px 0px 10px 0px #3A438533' }}
				>
					<LazyloadImg
						src={CLIENT_LOGO}
						alt={'Logo'}
						style={{ height: '72px', marginTop: '8px', marginBottom: '8px' }}
					></LazyloadImg>
					<div className="flex flex-col justify-between h-3/4 w-4/5 font-medium">
						<div className="flex flex-col justify-center gap-2">
							<FormControl>
								<TextField
									fullWidth
									id="outlined-basic"
									label="Email"
									variant="outlined"
									InputLabelProps={{ style: { fontSize: 16, paddingRight: 6 } }}
									inputProps={{ style: { fontSize: 16 } }}
									onChange={(e) => setEmail(e.target.value)}
									error={isEmail(email) || email.length === 0 ? false : true}
								/>
								{!isEmail(email) && email !== '' ? (
									<div className="h-4 flex flex-col justify-start items-start">
										<FormHelperText id="component-helper-text" error>
											Invalid Email Format
										</FormHelperText>
									</div>
								) : (
									<div className="h-4"></div>
								)}
							</FormControl>
							<FormControl fullWidth sx={{}} variant="outlined">
								<InputLabel
									style={{ fontSize: 16, paddingRight: 6 }}
									htmlFor="outlined-adornment-password"
								>
									Password
								</InputLabel>
								<OutlinedInput
									onChange={(e) => setPassword(e.target.value)}
									id="outlined-adornment-password"
									type={showPassword ? 'text' : 'password'}
									inputProps={{ style: { fontSize: 16 } }}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									label="Password"
								/>
								<div className="h-4"></div>
							</FormControl>
						</div>

						<div className="flex flex-col font-medium gap-2">
							<Button
								disabled={
									email === '' || !isEmail(email) || password === '' || loading
								}
								className="py-2 rounded-lg text-base font-semibold tracking-wider"
								variant="contained"
								onClick={handleSubmit}
							>
								{loading ? (
									<div className="flex flex-row justify-center items-center gap-4">
										<CircularProgress
											className="h-5 w-5"
											color="inherit"
										></CircularProgress>
										<div>{'Logging In...'}</div>
									</div>
								) : (
									<div>{'Log In'}</div>
								)}
							</Button>

							<div className="flex flex-row justify-center items-center">
								{/* <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onClick={() => setRememberMe(!rememberMe)}
                        sx={{ color: palette.primary.main }}
                      />
                    }
                    label={
                      <Typography
                        fontWeight={500}
                        fontSize={14}
                        color={palette.primary.main}
                      >
                        Remember Me
                      </Typography>
                    }
                  />
                </FormGroup> */}
								<Link to="/forgot-password">
									<Typography
										fontWeight={700}
										fontSize={16}
										color={palette.primary.main}
									>
										Forgot Password
									</Typography>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<BotBar></BotBar>
			<ZendeskChatWidget
				onFinishScriptLoad={() => {
					// @ts-ignore
					window.zESettings = {
						webWidget: {
							offset: {
								vertical: '80px',
								mobile: {
									vertical: '80px',
								},
							},
						},
					};
				}}
			/>
		</div>
	);
};

export default Login;
